<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems" :dates="[forms.begin, forms.end]">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
        <v-col cols="12" md="3">
          <Date-Picker v-model="forms.begin" label="Fecha inicio" offYear offLimit />
        </v-col>
        <v-col cols="12" md="3">
          <Date-Picker v-model="forms.end" label="Fecha fin" offYear offLimit />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="1">
          <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!(forms.begin && forms.end)" />
        </v-col>
      </v-row>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre_jefe`]="{ item }">
          {{ item.nombre_jefe + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.indice_rotacion`]="{ item }">
          {{ item.indice_rotacion ? item.indice_rotacion + "%"  : "0%"}}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components";
import moment from "moment";

export default {
  name: "turnover",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    dateSearch: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    headers: [
      { text: "ID departamento", align: "left", sortable: true, value: "iddepartamento" },
      { text: "Departamento", align: "left", sortable: true, value: "nombre_departamento" },
      { text: "ID usuario", align: "left", sortable: true, value: "idusuario" },
      { text: "Jefe", align: "left", sortable: true, value: "nombre_jefe" },
      { text: "Activos al inicio del periodo", align: "left", sortable: true, value: "activos_inicio_periodo" },
      { text: "Activos al final del periodo", align: "left", sortable: false, value: "activos_final_periodo" },
      { text: "Renuncias del periodo", align: "left", sortable: false, value: "renuncias_periodo" },
      { text: "Indice de rotación", align: "left", sortable: false, value: "indice_rotacion" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20,
    forms: {
      begin: "",
      end: "",
    },
  }),
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      let data = {
        fecha_inicio: this.forms.begin,
        fecha_fin: this.forms.end,
      };
      this.axios
        .post(services.routes.reports + '/turnover', { data })
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo_jefe = this.items[i].nombre_jefe + ' ' + this.items[i].apellido_paterno + ' ' + this.items[i].apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros obtenidos correctamente.";
        });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
